import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

import './styles.css'

function Sobre() {
  return (
    <>
      <Helmet>
        <title>Geosub | Sobre</title>
      </Helmet>
      <main>
        <Container className="sobre">
          <Row>
            <Col>
              <h1>Sobre</h1>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                A Geosub é uma empresa do grupo Geodrill e surgiu com a
                necessidade do mercado em encontrar uma empresa com expertise em
                sondagem geotécnica subaquática, recuperação e manutenção de
                estruturas submersas, bem como estruturas que ficam na zona de
                variação de marés e estruturas portuárias.
              </p>
              <p>
                A Geosub tem como uma de suas principais atividades de mergulho
                para sondagem geotécnica à seco em campanulas submersas.
              </p>
              <p>
                Contamos com uma variedade de configurações de plataformas,
                aptas a atender as alternativas de cada projeto.
              </p>
              <p>
                A Geosub também oferece o serviço de fiscalização de obras de
                mergulho com conferencia in loco contando assim com fiscais
                altamente qualificados.
              </p>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col className="step intro">
              <h4>Por que contratar a Geosub?</h4>
              <p>
                Acreditamos que temos 3 principais características que nos
                capacitam a enfrentar toda gama de desafios no mercado de
                sondagens geotécnicas.
              </p>
            </Col>
            <Col className="step step1">
              <h4>EXPERIÊNCIA</h4>
              <p>
                São mais de 20 anos de atuação no mercado brasileiro com um
                corpo técnico de profissionais sêniors acostumados a enfrentar
                desafios em diversos tipos de obras e projetos.
              </p>
            </Col>
            <Col className="step step2">
              <h4>AGILIDADE</h4>
              <p>
                Empresa sem burocracia, compacta, mas que pensa e age GRANDE.
                Contamos com uma rede qualificada de parceiros, unindo soluções
                práticas para entregar em prazos mais curtos.
              </p>
            </Col>
            <Col className="step step3">
              <h4>TECNOLOGIA</h4>
              <p>
                A experiência nos permitiu desenvolver equipamentos inovadores
                que oferecem resultados mais seguros e com mais qualidade,
                atendendo à crescente demanda do mercado.
              </p>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col>
              <h5>Diretores:</h5>
            </Col>
          </Row>
          <Row>
            <Col className="diretores">
              <h6>Eduardo Lopes Lobato</h6>
              <p>
                Engenheiro Civil Geotécnico, formado pela Universidade Federal
                Fluminense - UFF, em 1971.
              </p>
              <p>CREA RJ 1531D</p>
            </Col>
            <Col className="diretores">
              <h6>Geraldo de Oliveira Almeida</h6>
              <p>
                Formado em 1986 pela Universidade Federal Rural do Rio de
                Janeiro (UFFRJ), Geraldo é Geólogo Geotécnico.
              </p>
              <p>CREA RJ 861053121-6</p>
            </Col>
            <Col className="diretores">
              <h6>Lincoln José Coelho</h6>
              <p>
                Foi em 1984 que Lincoln Coelho se formou como Geólogo Geotécnico
                na Universidade Federal do Rio de Janeiro (UFRJ).
              </p>
              <p>CREA RJ 84107260-5</p>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <h5>Onde Estamos:</h5>
          </Row>
          <Row>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3673.84727275699!2d-43.35048044893314!3d-22.95585114532236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9bd9c782f6cb43%3A0xe7654fb5bd96b7ea!2sEstr.%20do%20Engenho%20d&#39;%C3%81gua%2C%201360%20-%20Anil%2C%20Rio%20de%20Janeiro%20-%20RJ%2C%2022765-240!5e0!3m2!1spt-BR!2sbr!4v1638189155518!5m2!1spt-BR!2sbr"
              width="600"
              height="450"
            ></iframe>
          </Row>
        </Container>
      </main>
    </>
  )
}

export default Sobre
