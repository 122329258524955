import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './styles.css'

function Footer() {
  return (
    <>
      <div className="footer">
        <Container>
          <Row className="footer-infos">
            <Col></Col>
            <Col>
              <h5>FALE CONOSCO</h5>
              <p>
                <strong>Telefones:</strong> +55 (21) 2427-6616 | +55 (21)
                2445-3170
              </p>
              <p>
                <strong>E-mail:</strong>{' '}
                <strong>
                  <a href="/contato">geraldo@geodrill.com.br</a>
                </strong>
              </p>
            </Col>
            <Col>
              <h5>ENDEREÇO</h5>
              <p>Estrada do Engenho D&apos;água, 1360</p>
              <p>Anil - Jacarepaguá - Rio de Janeiro</p>
              <p>
                +{' '}
                <strong>
                  <a href="/contato">Veja no mapa</a>.
                </strong>
              </p>
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <hr className="bottom fixclear" />
          </Row>
          <Row>
            <Col className="copyrigth">
              <p>
                &copy; 2021 Smithson do Brasil - Todos os Direitos Reservados.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Footer
