import React from 'react'
import { Helmet } from 'react-helmet'

import './styles.css'
import { Container, Row, Col } from 'react-bootstrap'

function SondagemPlataforma() {
  return (
    <>
      <Helmet>
        <title>Geosub | Sondagens Subaquáticas com Plataformas</title>
      </Helmet>
      <main className="sondagemPlataforma">
        <Container>
          <Row>
            <Col>
              <h1>Sondagens Subaquáticas com Plataformas</h1>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col>
              <img
                src="/images/imagem-plataformas-elevatorias-2.jpg"
                alt=""
                className="img-extra1"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                As plataformas auto-elevatórias Geodrill são construídas com
                sistema de elevação hidráulico permitindo que o convés onde
                estão instalados os equipamentos fique acima da linha d`água de
                maré máxima e das maiores ondas no período, fazendo com que as
                operações de sondagem e ensaios sejam executados livre da
                oscilação das ondas e correnteza marítima.
              </p>

              <p>
                A Geodrill dispõe de plataformas para operar em lâmina d`água
                variável de apenas 60cm a 20,00m dependendo das condições do mar
                no local de operação.
              </p>

              <p>
                As plataformas de menor porte são adequadas para locais com
                interferências como blocos e afloramentos rochosos a pouca
                profundidade.
              </p>

              <p>
                Nossas plataformas são construídas em módulos desmontáveis,
                transportadas por via rodoviária e montadas com guindastes de
                pequeno porte na própria região dos serviços.
              </p>

              <p>
                As plataformas atuam preferencialmente em profundidades onde as
                campânulas não operam como lâmina d`água menor que 7,0m.ou
                quando as condições favoráveis do mar possibilitam que as
                plataformas sejam mais econômicas que as campânulas.
              </p>
            </Col>
          </Row>

          <Row>
            <Col>
              <img
                src="/images/imagem-plataformas-elevatorias-4.jpg"
                alt=""
                className="img-extra"
              />
            </Col>
            <Col>
              <img
                src="/images/imagem-plataformas-elevatorias-5.jpg"
                alt=""
                className="img-extra"
              />
            </Col>
          </Row>
        </Container>
      </main>
    </>
  )
}

export default SondagemPlataforma
