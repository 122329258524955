import React from 'react'
import { Helmet } from 'react-helmet'

import './styles.css'

import { Container, Row, Col } from 'react-bootstrap'

import AccordionContainerSondagens from '../../components/AccordionContainerSondagens'

function SondagemGeotecnica() {
  return (
    <>
      <Helmet>
        <title>Geosub | Sondagens e Ensaios Geotécnicos</title>
      </Helmet>
      <main className="sondagemGeotecnica">
        <Container>
          <Row>
            <Col>
              <h1>Sondagens e Ensaios Geotécnicos</h1>
              <hr />
              <span>
                <img src="/images/imagem-sondagem-geotecnica-2.jpg" alt="" />
                <h5>&#62; Sondagens Geotécnicas em Terra</h5>
                <p>
                  A Geodrill dispõe de todos os equipamentos necessários para
                  execução dos mais diversos tipos de sondagem e ensaios
                  geotécnicos em terra, a saber:
                </p>
              </span>
            </Col>
          </Row>
          <Row>
            <AccordionContainerSondagens />
          </Row>
        </Container>
      </main>
    </>
  )
}

export default SondagemGeotecnica
