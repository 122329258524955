import React from 'react'

import './styles.css'

import { Carousel, Col, Container, Row, Image } from 'react-bootstrap'

function CarouselContainer() {
  return (
    <Container className="home">
      <Row>
        <Col>
          <Carousel fade variant="dark">
            <Carousel.Item interval={3000}>
              <Image
                className="d-block w-100"
                src="/images/Carousel1.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item interval={3000}>
              <Image
                className="d-block w-100"
                src="/images/Carousel2.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item interval={3000}>
              <Image
                className="d-block w-100"
                src="/images/Carousel3.jpg"
                alt=""
              />
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
    </Container>
  )
}

export default CarouselContainer
