import React from 'react'
import { Helmet } from 'react-helmet'

import './styles.css'

import { Container, Row, Col, Image } from 'react-bootstrap'

function SondagemVibracore() {
  return (
    <>
      <Helmet>
        <title>Geosub | Sondagens Subaquáticas com Vibracore</title>
      </Helmet>
      <main className="sondagemVibracore">
        <Container>
          <Row>
            <Col>
              <h1>Sondagens Subaquáticas com Vibracore</h1>
              <hr />
              <p>
                <Image
                  src="/images/imagem-sondagem-com-vibracore-2.jpg"
                  alt=""
                  fluid
                />
                A sondagem com Vibracore é um processo rápido de coleta de
                amostras semi-deformadas das camadas superficiais do solo dos
                fundos do rio e do mar para subsidiar estudos geotécnicos, como
                identificação de bolsões de solo mole, estudos de dragagem,
                identificação e cubagem e jazidas de areia e estudos de impacto
                ambiental, todos baseados na análise física e química das
                amostras.
              </p>
              <p>
                As análises químicas das amostras oferecem os seguintes dados:
                contaminantes, minerais, PH, EH etc.
              </p>
              <p>
                Já as análises físicas das amostras, oferecem: granulometria,
                densidade, limites de Atteberg, teor de matéria orgânica,
                umidade etc,
              </p>
              <p>
                A amostragem com Vibracore é adequada a solos de baixa
                resistência, não consolidados ou semi-consolidados, não se
                adequando a solos de grande resistência, uma vez que tubo
                amostrador não consegue penetra no solo.
              </p>
            </Col>
          </Row>

          <Row>
            <Col>
              <h5>Equipamento e procedimentos</h5>
              <p>
                A amostragem com Vibracore consiste na cravação contínua por
                vibração de alta performance de um tubo amostrador de alumínio
                com comprimento de 3m a 6m, diâmetro 3”, contendo válvulas
                especiais de retenção das amostras, fundamentais para o sucesso
                da amostragem.
              </p>
              <p>
                A coleta e ensaios com amostrador Vibracore são executados a
                partir de embarcações devidamente preparadas para operar o
                sistema de elevação e descida do Vibrador – A Frame- e da
                estrutura de guias que deve ficar apoiada no fundo marinho ou
                fluvial para garantir a verticalidade do amostrador durante a
                cravação, situação também fundamental para o sucesso da
                amostragem.
              </p>
              <p>
                As amostras coletadas são contínuas e podem ser analisadas na
                própria embarcação ou encaminhadas para laboratórios
                especializados com os cuidados prévios de vedação, etiquetagem,
                fotografia e embalagem.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Image
                src="/images/imagem-sondagem-com-vibracore-3.jpg"
                alt=""
                className="img-extra"
                fluid
              />
            </Col>
            <Col>
              <Image
                src="/images/imagem-sondagem-com-vibracore-4.jpg"
                alt=""
                className="img-extra"
                fluid
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>
                <p>
                  A produção diária é variável dependendo basicamente dos
                  seguintes fatores:
                </p>
              </strong>
              <ul>
                <li>a qualidade do equipamento vibracore;</li>
                <li>o treinamento da equipe operacional;</li>
                <li>a eficiência do sistema de guindastes;</li>
                <li>
                  as condições ambientais como vento, correnteza e ondas) que
                  impactam no deslocamento e fundeio da embarcação; e
                </li>
                <li>
                  se o tubo amostrador é aberto no barco para análise ou enviado
                  para laboratório.
                </li>
              </ul>
              <strong>
                <p>
                  Os resultados da amostragem deverão ser apresentados em
                  relatório contendo as seguintes informações:
                </p>
              </strong>
              <ul>
                <li>data e hora da amostragem;</li>
                <li>O número da sondagem;</li>
                <li>as coordenadas do furo;</li>
                <li>a lâmina d’água;</li>
                <li>a cota do fundo do rio ou do mar;</li>
                <li>a profundidade atingida pelo tubo coletor; e</li>
                <li>a profundidade atingida pelo tubo coletor; e</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <Image
                src="/images/imagem-sondagem-com-vibracore-5.jpg"
                alt=""
                className="img-extra"
                fluid
              />
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </main>
    </>
  )
}

export default SondagemVibracore
