import React from 'react'
import { Helmet } from 'react-helmet'

import './styles.css'

import { Container, Row, Col } from 'react-bootstrap'

function ObrasCivis() {
  return (
    <>
      <Helmet>
        <title>Geosub | Obras Civis</title>
      </Helmet>
      <main className="obrasCivis">
        <Container>
          <Row>
            <Col>
              <h1>Obras Civis</h1>
              <hr />
            </Col>
          </Row>
          <Row>
            <p>
              <img src="/images/imagem-obras-civis-2.jpg" alt="" />
              <h4>Infraestrutura para Telecomunicaçőes:</h4>
              <p>
                <ul>
                  <li>Infraestrutura para Telecomunicaçőes:</li>
                  <li>Fundaçőes de Torres</li>
                  <li>Estaçőes para Telefonia Fixa</li>
                </ul>
              </p>
            </p>
          </Row>
        </Container>
      </main>
    </>
  )
}

export default ObrasCivis
