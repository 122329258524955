import React from 'react'
import { Helmet } from 'react-helmet'

import './styles.css'

import { Container, Row, Col } from 'react-bootstrap'

function PlataformasElevatorias() {
  return (
    <>
      <Helmet>
        <title>Geosub | Aluguel de Plataformas Autoelevatórias</title>
      </Helmet>
      <main className="plataformasElevatorias">
        <Container>
          <Row>
            <Col>
              <h1>Aluguel de Plataformas Elevatórias</h1>
              <hr />
              <p>
                As plataformas autoelevatórias (mini jack-up) são utilizadas
                para sondagens e outros serviços de apoio marítimo.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <img
                src="/images/imagem-plataformas-elevatorias-3.jpg"
                alt=""
                className="img-extra"
              />
            </Col>
            <Col>
              <img
                src="/images/imagem-plataformas-elevatorias-2.jpg"
                alt=""
                className="img-extra img-extra2"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                Oferecemos diversos modelos, com área de convés variável de 36m²
                a 86m² e operar em lâmina d’água de 1m a 25m.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <img
                src="/images/imagem-plataformas-elevatorias-5.jpg"
                alt=""
                className="img-extra"
              />
            </Col>

            <Col>
              <img
                src="/images/imagem-plataformas-elevatorias-4.jpg"
                alt=""
                className="img-extra"
              />
            </Col>
          </Row>
        </Container>
      </main>
    </>
  )
}

export default PlataformasElevatorias
