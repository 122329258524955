import React from 'react'

import './styles.css'

import { Col, Container, Row, Accordion } from 'react-bootstrap'

function AccordionContainerContencao() {
  return (
    <Container>
      <Row>
        <Col>
          <Accordion flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Cortina Atirantada</Accordion.Header>
              <Accordion.Body>
                <p>
                  As cortinas são muros delgados de concreto armado, com
                  espessuras entre 20 e 30cm, contidas por tirantes protendidos,
                  com cargas de trabalho normalmente entre 15 e 60 tf.
                </p>

                <p>
                  As cortinas atirantadas são em geral verticais tendo os
                  tirantes distribuídos de maneira aproximadamente uniforme, com
                  espaçamentos variáveis em função da altura da contenção e dos
                  esforços atuantes geralmente entorno de 2,0 a 3,5m.
                </p>

                <p>
                  Constituem um tipo de arrimo extremamente versátil, com grande
                  flexibilidade construtiva, pois pode ser executado em
                  praticamente qualquer tipo de terreno, com grandes alturas (p.
                  ex acima de 20m), em corte ou aterro, a meia encosta, ou em
                  situações de pouco espaço para construção do muro., etc.
                  Dependendo das condições locais, podem ser executadas também
                  em sentido descendente, de cima para baixo, protendendo-se
                  parcialmente os tirantes das linhas superiores de forma a
                  permitir o prosseguimento da escavação, concretagem da cortina
                  e o atirantamento das linhas progressivamente mais baixas. As
                  cortinas atirantadas são associadas a fundações profundas tipo
                  micro estacas e a outros tipos de estabilização,( por ex:
                  concreto projetado) visando atender exigências locais
                  específicas.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Grelha Atirantada</Accordion.Header>
              <Accordion.Body>
                <p>
                  A Grelha é um reticulado de vigas de concreto armado
                  geralmente horizontais e verticais , com tirantes fixados em
                  cada nó. A grelha é utilizada com mais freqüência quando se
                  deseja reforçar e melhorar a estabilidade de muros já
                  existentes seja de concreto armado, alvenaria de pedra, etc, o
                  que corresponde praticamente a transformá-los em cortinas
                  atirantadas. As distâncias entre vigas da grelha sendo
                  relativamente pequenas, por efeito de arco se garante a
                  estabilidade dos trechos do muro a reforçar não situados
                  diretamente sob a grelha. Esta solução também pode ser
                  utilizada em taludes de solo ou rocha que contenham lascas e
                  pequenos blocos soltos. Nestes casos se aplica a grelha sobre
                  os taludes previamente recobertos por concreto projetado sobre
                  tela metálica.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Muro em &quot;L&quot; de concreto armado
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Este tipo de muro de arrimo é construído totalmente de
                  concreto armado, tornando possível obter-se seções
                  transversais/esbeltas. É comum construir-se a base do lado do
                  maciço a arrimar, de modo que o próprio peso de terra sobre
                  ela contrabalança a ação do empuxo. Os muros em “L” requerem
                  bom terreno de fundação, drenagem eficiente e verificação
                  cuidadosa quanto à possibilidade de deslocamento horizontal
                  (deslizamento). Como o empuxo e o momento fletor no parâmetro
                  do muro aumentam rapidamente à medida que cresce a altura a
                  arrimar (proporcionalmente ao quadrado e cubo
                  respectivamente), devem ser evitadas obras excessivamente
                  altas (acima de 5,0m) utilizando este tipo de solução. É
                  também possível posicionar a base do muro em “L” no lado
                  externo , isto é, do lado oposto ao do maciço a arrimar. A
                  opção pela colocação da base numa ou noutra posição, depende
                  basicamente das condições locais.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                Muro em &quot;L&quot; de concreto armado, com Contrafortes
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Os muros de concreto em “L”, com contrafortes internos ou
                  externos são uma derivação do tipo “L” simples. A introdução
                  dos contrafortes melhora sensivelmente a estrutura, tornando-a
                  mais rígida e apta a resistir esforços maiores. Aplicam-se
                  aqui as mesmas observações válidas para o tipo mais simples,
                  quanto à fundação e limitações de altura. No caso de
                  contrafortes internos, a drenagem e a compactação do reaterro
                  ficam dificultadas e na configuração com contrafortes
                  externos, necessariamente haverá menos espaço livre na frente
                  do muro de arrimo.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Muro de Arrimo de Gravidade</Accordion.Header>
              <Accordion.Body>
                <p>
                  São assim chamados os muros de arrimo maciços e monolíticos,
                  cuja estabilidade é derivada do peso próprio. Geralmente são
                  construídos de pedra argamassada ou concreto ciclópico e tem
                  seção transversal de forma trapezoidal. Constituem um dos
                  tipos mais antigos e comuns, sendo também os mais econômicos
                  em grande número de casos. Exigem bom terreno de fundação,
                  drenagem eficiente e prevenção contra a tendência ao
                  deslizamento. São especialmente econômicos para alturas
                  modestas e situações onde há disponibilidade de pedras e mão
                  de obra comum. A implantação destes muros a meia encosta ou em
                  locais onde haja possibilidade de erosão sob a base, deve ser
                  cuidadosamente considerada.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>Muro de Arrimo de Gabiões</Accordion.Header>
              <Accordion.Body>
                <p>
                  Os muros de arrimo de gabiões é um tipo de muro de gravidade.
                  Os gabiões são gaiolas com formato variáveis formados por
                  redes de aço zincado, com malha hexagonal, preenchida com
                  pedra de mão, maciças, com pesos unitários entre 2 e 15 kgf.
                  Os tipos mais comuns de gaiolas são gabiões-caixa,
                  gabiões-manta e gabiões-saco. As principais vantagens desta
                  solução são: - elevada permeabilidade e grande flexibilidade,
                  permitindo construir estruturas monolíticas altamente
                  drenantes e capazes de aceitar deslocamentos e deformações sem
                  se romperem. - rapidez de construção, facilidade de mão de
                  obra e utilização direta de material natural. As aplicações
                  principais são para muros de arrimo, proteção de margens de
                  cursos d’água, controle de erosão e obra de emergência. Os
                  muros de arrimo de gabiões são muros de gravidade, pois
                  derivam sua estabilidade do peso próprio da estrutura. Assim
                  sendo, o terreno de fundação desempenha papel preponderante
                  neste tipo de arrimo. Estruturas a serem implantadas a meia
                  encosta ou sobre terrenos fracos, devem ser cuidadosamente
                  analisadas.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                Concreto projetado sobre Tela metálica fixada por chumbadores
                (Solo grampeado - Soil Nailing)
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  A utilização desta solução para estabilização de taludes de
                  corte em solo naturais também denominado solo grampeado ou
                  soil nailing, aplicada no Brasil desde 1970, consiste em
                  reforçar o solo com a introdução de chumbadores ou grampos
                  envolvidos por calda de cimento, geralmente barras de aço,
                  espaçados convenientemente no sentido horizontal e vertical de
                  forma a se obter maiores esforços resistentes de tração e
                  cisalhamento. O processo construtivo é realizado em etapas
                  sucessivas e descendentes onde são realizadas as seguintes
                  etapas: escavação limitada em geral a alturas de 1,0 a 2,0m,
                  perfuração, instalação e injeção dos grampos, aplicação sobre
                  a superfície escavada de tela metálica, instalação dos
                  acessórios de drenagem como barbaças – drenos curtos e drenos
                  profundos.e por fim a projeção de uma camada de concreto de 7
                  a 10cm de espessura. Esta solução apresenta as seguintes
                  vantagens: permite grande adaptação do projeto às condições
                  geométricas do talude, não requer grandes escavações, elimina
                  a necessidade de formas, escoramento e concretagens, utiliza
                  poucos andaimes e o tempo de execução é em geral menor, o que
                  implica em uma obra de menor custo em relação a outras
                  soluções convencionais. O concreto projeto associado a tela
                  metálica e grampos também é utilizado em contenção de taludes
                  rochosos O concreto projetado isoladamente é uma solução de
                  proteção superficial do terreno impermeabilizando-o. Nesta
                  situação o concreto é aplicado com uma espessura da ordem de
                  6,0cm sobre uma tela metálica eletrosoldada cuja função é
                  evitar rachaduras no concreto projetado sob efeito das
                  intempéries. Esta solução é adotada em geral sobre terrenos
                  com boas características de estabilidade quando secos porém se
                  tornam rapidamente instáveis sob efeitos de chuvas.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                Cortina de microestacas com tela metálica, concreto projetado e
                tirantes
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  É uma solução de arrimo formada pela sucessão de microestacas
                  justapostas ou bastante próximos umas das outras com distância
                  entre eixos da ordem de 1,5 vezes o diâmetro associadas a
                  vigas horizontais atirantadas. Utiliza-se ainda ao final da
                  construção da cortina, acessórios de drenagem, tela metálica
                  soldada, que cobre todo o conjunto e sobre a qual aplica-se
                  concreto projetado. Este tipo de solução é indicado quando se
                  pretende implantar uma contenção em terreno relativamente
                  instável, um subsolo por exemplo, que tem no seu topo imóveis
                  ou pistas de rolamento que não podem ser afetados. Assim esta
                  solução permite quando se for escavar já se encontre condições
                  de estabilidade e segurança durante o período de execução dos
                  tirantes e vigas de concreto.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>Injeções de consolidação</Accordion.Header>
              <Accordion.Body>
                <p>
                  As injeções constituem o processo pelo qual se introduz no
                  solo uma mistura líquida, que depois de determinado tempo irá
                  dar pega, solidificando-se. Um dos sistemas mais usados para
                  se executar injeções é com a utilização de tubos com válvulas
                  “manchete”. Os tirantes e as microestacas injetadas constituem
                  exemplos comuns de aplicação de injeções, nos quais a mistura
                  injetada é a calda de cimento, geralmente com traço a/c  0,5,
                  em peso. As injeções propiciam uma melhoria das
                  características do terreno (consolidação) e ao mesmo tempo
                  reduzem a permeabilidade (impermeabilização). As injeções
                  podem ser usadas numa variedade de situações, quando se deseja
                  obter melhoria da resistência do terreno e/ou redução da
                  permeabilidade. A utilização de tubos com válvulas “manchette”
                  permite injeções em várias profundidades segundo várias
                  direções, viabilizando as mais diversas disposições espaciais.
                  A natureza da calda a injetar pode variar das mais simples
                  (calda de cimento, argila-cimento ) até produtos químicos
                  (silicato de sódio, resinas orgânicas), em função
                  principalmente da granulometria do terreno e da finalidade da
                  injeção.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>Tirantes Protendidos</Accordion.Header>
              <Accordion.Body>
                <p>
                  São elementos destinados a receber esforços de tração e são
                  sempre protendidos. São compostos basicamente da cabeça de
                  ancoragem, trecho livre e trecho ancorado. Os tirantes são
                  executados mediante a perfuração prévia do terreno e posterior
                  injeção de calda de cimento, que solidariza o trecho de
                  ancoragem ao terreno. O trecho livre une a cabeça de ancoragem
                  ao trecho ancorado e pode ser distendido livremente ,
                  permitindo que o tirante seja protendido.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
              <Accordion.Header>Chumbadores</Accordion.Header>
              <Accordion.Body>
                <p>
                  Destinam-se a trabalhar a tração, mas não são protendidos.
                  Podem ser injetados de várias maneiras, geralmente ao longo de
                  todo o comprimento. São constituídos frequentemente por uma
                  barra de aço com extremidade externa fixada por conjunto de
                  placa de aço, rosca e porca. A faixa de cargas de trabalho é
                  normalmente menor que aquela dos tirantes, ficando abaixo de
                  15tf.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="11">
              <Accordion.Header>
                Dreno Sub Horizontal Profundo - DHP
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  São assim chamados os dispositivos tubulares instalados em um
                  maciço, com pequeno ângulo de inclinação sobre a horizontal,
                  destinados a interceptar e conduzir para a superfície, águas
                  subterrâneas, principalmente aquelas do lençol freático. O
                  tipo mais comumente utilizado é constituído por um tubo de PVC
                  rígido de 50 a 75mm de diâmetro, provido de pequenas
                  perfurações ou fendas, ao longo de todo comprimento, exceto em
                  alguns poucos metros junto à extremidade que aparece na
                  superfície do terreno. Para impedir que a água captada do
                  terreno arraste partículas finas de solo, o tubo de PVC
                  perfurado deve ser revestido com um material filtrante
                  adequado. O geotextil Bidim é utilizado para desempenhar este
                  papel porque alia vantagens de bom desempenho e grande
                  durabilidade podendo ser utilizado também a tela de nylon com
                  malha adequada. Os drenos são montados previamente e são
                  instalados à medida que as perfurações no terreno vão sendo
                  concluídas. Geralmente são construídos drenos com ângulo de 5°
                  acima da horizontal, para facilitar o escoamento das águas. As
                  profundidades e o espaçamento entre drenos são função da
                  situação local, sendo comuns drenos de 15,0 a 20,0 ou mais. Ao
                  se analisar a estabilização de maciços rochosos ou terrosos,
                  deve-se sempre considerar a conveniência de instalar drenos
                  sub-horizontais. Em inúmeros casos, apenas a medida de
                  instalar estes drenos em lugares apropriados já produz efeitos
                  imediatos, com grande melhoria das condições gerais de
                  estabilidade. A eliminação de drenos necessários, por
                  pseudo-economia ou por comodidade , acabará gerando prejuízos
                  no futuro, devido a deslizamentos e erosões. Apud
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </Container>
  )
}

export default AccordionContainerContencao
