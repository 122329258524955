import React from 'react'
import { Helmet } from 'react-helmet'

import './styles.css'

import CarouselContainer from '../../components/CarouselContainer'

function Home() {
  return (
    <>
      <Helmet>
        <title>Geosub</title>
      </Helmet>
      <main>
        <CarouselContainer />
      </main>
    </>
  )
}

export default Home
