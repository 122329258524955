import React from 'react'
import { Helmet } from 'react-helmet'

import './styles.css'

import { Container, Row, Col } from 'react-bootstrap'

function FundacoesEspeciais() {
  return (
    <>
      <Helmet>
        <title>Geosub | Fundações Especiais - Estaca Raiz</title>
      </Helmet>
      <main className="fundacoesEspeciais">
        <Container>
          <Row>
            <Col>
              <h1>Fundações Especiais - Estaca Raiz</h1>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                <img src="/images/imagem-fundacao-especial-2.jpg" alt="" />A
                Geodrill executa estaca escavadas injetadas de pequeno a médio
                diâmetro, moldadas “in loco”, tipo raiz.
              </p>
              <p>
                A estaca raiz é uma estaca armada e concretada com argamassa de
                cimento e areia, moldada “in loco” e executada através de
                perfuração rotativa ou roto-percussiva e revestindo o furo total
                ou parcialmente com tubos metálicos rosqueados na medida do
                avanço da perfuração. Estes tubos posteriormente são removidos
                após a instalação da armadura e preenchimento do furo com
                argamassa.
              </p>
              <p>
                A Geodrill executa estaca raiz com cargas admissíveis de 10 a
                100 toneladas, dependendo da armadura e do diâmetro do furo, que
                pode variar entre 120mm a 310mm. Cargas mais elevadas podem ser
                executadas, porém sob consulta.
              </p>
              <strong>
                <p>
                  A estaca raiz, por ser de pequeno a médio diâmetro, é
                  executada com equipamentos de pequeno a médio porte, podendo
                  atender, entre outras, as seguintes situações:
                </p>
              </strong>
              <ul>
                <li>Estabilização de encosta com reticulado de estacas;</li>
                <li>
                  Reforço de fundação com restrição de espaço e pé direito;
                </li>
                <li>
                  Fundação em terrenos com blocos de rocha ou antigas fundações;
                </li>
                <li>
                  Fundações novas com restrição de pé direito ou espaço em
                  planta;
                </li>
                <li>Fundações novas com restrição de vibração e de ruídos;</li>
                <li>Fundações com esforços de tração e compressão.</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <img
                src="/images/imagem-fundacao-especial-3.jpg"
                alt=""
                className="img-extra"
              />
            </Col>
            <Col>
              <img
                src="/images/imagem-fundacao-especial-4.jpg"
                alt=""
                className="img-extra"
              />
            </Col>
          </Row>
        </Container>
      </main>
    </>
  )
}

export default FundacoesEspeciais
