import React from 'react'

import { Col, Row } from 'react-bootstrap'
import { GridItems } from './gridItems'

export function Grid() {
  return (
    <>
      <Row className="grid">
        <Row>
          <GridItems
            url="https://www.acciona.com.br"
            image="/images/acciona.jpg"
          />
          <GridItems
            url="https://www.andradegutierrez.com.br"
            image="/images/ag.jpg"
          />
          <GridItems
            url="https://brasil.angloamerican.com/pt-pt"
            image="/images/angloamerican.jpg"
          />
          <GridItems url="https://www.csn.com.br" image="/images/csn.jpg" />
          <GridItems image="/images/eja.jpg" />
          <GridItems image="/images/engevix.jpg" />
        </Row>
        <Row>
          <GridItems image="/images/ferrous.jpg" />
          <GridItems
            url="https://www2.gerdau.com.br"
            image="/images/gerdau.jpg"
          />
          <GridItems
            url="https://www.invepar.com.br"
            image="/images/invepar.jpg"
          />
          <GridItems image="/images/llx.jpg" />
          <GridItems
            url="https://grupomarquise.com.br"
            image="/images/marquise.jpg"
          />
          <GridItems url="http://www.mmx.com.br" image="/images/mmx.jpg" />
        </Row>
        <Row></Row>
        <GridItems image="/images/mpe.jpg" />
        <GridItems image="/images/mpx.jpg" />
        <GridItems image="/images/oas.jpg" />
        <GridItems
          url="https://www.oec-eng.com"
          image="/images/odebrecht.jpg"
        />
        <GridItems url="https://www.planave.com" image="/images/planave.jpg" />
        <GridItems
          url="http://transpetro.com.br/transpetro-institucional/"
          image="/images/transpetro.jpg"
        />
        <Row>
          <GridItems
            url="http://www.vale.com/brasil/PT/Paginas/default.aspx"
            image="/images/vale.jpg"
          />
          <GridItems
            url="https://petrobras.com.br/pt/"
            image="/images/logo-petrobras.jpg"
          />
          <GridItems
            url="https://www.icmbio.gov.br/portal/"
            image="/images/logo-icmbio.jpg"
          />
          <GridItems
            url="https://www.claro.com.br"
            image="/images/logo-claro.jpg"
          />
          <GridItems
            url="https://www.cob.org.br/pt/"
            image="/images/logo-rio2016.jpg"
          />
          <Col></Col>
        </Row>
      </Row>
    </>
  )
}
