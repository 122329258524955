/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'

import './styles.css'

import { Col, Container, Row, Accordion, Offcanvas } from 'react-bootstrap'

function AccordionContainerSondagens() {
  const [showVT, setShowVT] = useState(false)
  const handleCloseVT = () => setShowVT(false)
  const handleShowVT = () => setShowVT(true)

  const [showCPTU, setShowCPTU] = useState(false)
  const handleCloseCPTU = () => setShowCPTU(false)
  const handleShowCPTU = () => setShowCPTU(true)

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Accordion flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Sondagem a Percussão</Accordion.Header>
                <Accordion.Body>
                  <h5>Ensaio SPT - Standard Penetration Test</h5>
                  <p>
                    O ensaio SPT normatizado no Brasil pela NBR 6484/2001 é,
                    reconhecidamente, a mais popular, rotineira e econômica
                    ferramenta de investigação geotécnica em praticamente todo o
                    mundo. Métodos rotineiros de projeto de fundações diretas e
                    profundas usam sistematicamente os resultados do ensaio SPT,
                    especialmente no Brasil.
                  </p>
                  <p>
                    O ensaio SPT constitui-se em medir a resistência a cravação
                    no solo de um amostrador padrão conjugado a uma sondagem de
                    simples reconhecimento. A perfuração é obtida por tradagem e
                    circulação de água, utilizando-se um trépano de lavagem como
                    ferramenta de escavação. Amostras representativas do solo
                    são coletadas a cada metro de profundidade por meio do
                    amostrador padrão que tem diâmetro externo de 50mm. Com as
                    amostras coletadas é possível obter indicativo da densidade
                    dos solos granulares (areias etc) e também na identificação
                    da consistência de solos coesivos (argilas, siltes etc).
                  </p>
                  <h5>Procedimento do ensaio SPT:</h5>
                  <p>
                    O ensaio consiste na cravação do amostrador padrão
                    posicionado no fundo da perfuração (revestida ou não) e na
                    profundidade de ensaio, usando-se um peso (martelo) de 65kg
                    em queda livre de uma altura de 75cm. Inicialmente coloca-se
                    cuidadosamente o martelo sobre a cabeça de bater conectada à
                    composição de hastes e mede-se a penetração da composição
                    decorrente do peso próprio do martelo e, caso ela seja
                    representativa, anota-se a penetração no boletim de ensaio
                    e, caso não ocorra penetração, marca-se na composição de
                    hastes três segmentos de 15cm e na medida que se faz a
                    cravação conta-se o número de golpes para cravar cada
                    segmento de 15cm. O número de golpes Nspt utilizado nos
                    projetos de engenharia é a soma dos valores correspondentes
                    aos últimos 30cm de penetração do amostrador.
                  </p>
                  <h5>Ensaio SPT-T</h5>
                  <p>
                    A associação do torque com a sondagem de simples
                    reconhecimento com ensaio SPT é denominada SPT-T. O torque é
                    aplicado na parte superior da composição da haste, de modo a
                    rotacionar o amostrador previamente cravado no terreno para
                    o ensaio SPT. Essa medida, obtida com auxílio de
                    torquímetro, tem como objetivo principal fornecer um dado
                    adicional à resistência à penetração e sua interpretração
                    permite determinar o atrito ou adesão amostrador – solo que
                    pode ser útil na determinação das características físicas do
                    perfil do subsolo.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Sondagem Mista</Accordion.Header>
                <Accordion.Body>Em breve.</Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Sondagem Rotativa</Accordion.Header>
                <Accordion.Body>Em breve.</Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Sondagem a Trado</Accordion.Header>
                <Accordion.Body>Em breve.</Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Poço de Inspeção</Accordion.Header>
                <Accordion.Body>Em breve.</Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  Coleta de Amostras Indeformadas
                </Accordion.Header>
                <Accordion.Body>Em breve.</Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  Ensaios Geotécnicos e Instrumentação
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      <a className="texto-para-canvas" onClick={handleShowCPTU}>
                        CPTU
                      </a>{' '}
                      (Ensaios de Cone e Piezocone)
                    </li>
                    <li>
                      <a className="texto-para-canvas" onClick={handleShowVT}>
                        Vane Test
                      </a>
                    </li>
                    <li>Permeabilidade em Solo e Rocha</li>
                    <li>Ensaio de Perda D&apos;água</li>
                    <li>Medidor de Nível D&apos;água</li>
                    <li>Piezômetro</li>
                    <li>Inclinômetro</li>
                    <li>Bench Mark</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      <Offcanvas show={showVT} onHide={handleCloseVT}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Ensaios de Palheta – Vane Test</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p>
            O ensaio de palheta (Vane Test) é tradicionalmente empregado na
            determinação da resistência ao cisalhamento não drenada (Su) de
            depósitos de argilas moles in situ.
          </p>
          <h5>Equipamentos e procedimentos</h5>
          <p>
            O ensaio de palheta é executado conforme a Norma NBR 10905 ( MB 3122
            da ABNT)
          </p>
          <p>
            O ensaio consiste em utilizar uma palheta de seção em forma de cruz
            que, quando cravada em argilas saturadas de consistência mole a
            rija, é submetida a um torque necessário para cisalhar o solo por
            rotação em condições não drenadas. É necessário, portanto, o
            conhecimento prévio da natureza do solo onde será realizado o
            ensaio, não só para avaliar a sua aplicabilidade, como para,
            posteriormente, interpretar adequadamente os resultados. A palheta
            especificada na Norma Brasileira apresenta desempenho satisfatório
            em argilas com resistências inferiores a 50 Kpa. A
          </p>
          <h5>
            Algumas das recomendações de natureza prática para definir a
            usabilidade do ensaio são:
          </h5>
          <p>
            a) N spt menor ou igual a 2 , correspondendo a resistência de
            penetração ( qc) menor ou igual a 1000 kpa;
            <br />
            b) matriz predominantemente argilosa ( &gt; 50% passando na peneira
            # 200, LL &gt; 25, IP &gt; 4 );
            <br />
            c) ausência de lentes de areia ( a ser definida previamente por
            ensaos de penetração)
          </p>
          <h5>As principais características do ensaio e equipamentos são:</h5>
          <p>
            1- A palheta em forma de cruz é constituída de 4 aletas, fabricadas
            em aço de alta resistência, com diâmetro de 65mm e altura de 130mm (
            altura igual ao dobro do diâmetro). Admite-se palheta retangular
            menor ( 50mm de diâmetro e 100mm de altura quando o ensaio for
            realizado em argilas rijas ( Su &gt; 50Kpa)
          </p>
          <p>
            2- A haste fabricada em aço capaz de suportar os torques aplicados,
            conduz a palheta até a profundidade do ensaio.
          </p>
          <p>
            3- O equipamento de aplicação e medição do torque, projetado para
            imprimir uma rotação ao conjunto de haste fina/ palheta de 6 ± 0,6º/
            min, deve possuir mecanismo de coroa e pinhão, acionado por manivela
            ou por motor elétrico.
          </p>
          <span>Texto com base em Schnaid&Odeberecht – Ensaios de campo</span>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas show={showCPTU} onHide={handleCloseCPTU}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>CPTU</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p>Em breve.</p>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default AccordionContainerSondagens
