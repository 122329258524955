import React from 'react'
import { Helmet } from 'react-helmet'

import './styles.css'

import { Container, Row, Col } from 'react-bootstrap'

import AccordionContainerContencao from '../../components/AccordionContainerContencao'

function ContencaoDeEncostas() {
  return (
    <>
      <Helmet>
        <title>Geosub | Contenção de Encostas</title>
      </Helmet>
      <main className="contencaoDeEncostas">
        <Container>
          <Row>
            <Col>
              <h1>Contenção de Encostas</h1>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                <img src="/images/imagem-contencao-de-encostas-2.jpg" alt="" />
                Os principais tipos de obras de contenção e estabilização de
                taludes e seus principais componentes executadas pela Geodrill,
                são:
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <AccordionContainerContencao />
            </Col>
          </Row>
        </Container>
      </main>
    </>
  )
}

export default ContencaoDeEncostas
