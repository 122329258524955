import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

import './styles.css'

function Contatos() {
  return (
    <>
      <Helmet>
        <title>Geosub | Contatos</title>
      </Helmet>
      <main>
        <Container className="contatos">
          <Row>
            <Col>
              <h1>Contatos</h1>
              <hr />
              <p>
                Se você possui alguma dúvida em relação aos nossos serviços ou
                deseja saber mais sobre a atuação da Geosub Investigações
                Geotécnicas em todo o Brasil, por favor, entre com contato
                conosco:
              </p>
              <h3>Por telefone:</h3>
              <p>Tel: (21) 2427-6616 / (21) 2445-3170</p>
              <h3>Endereço:</h3>
              <p>Estrada do Engenho Dagua, 1360</p>
              <p>Anil – Jacarepaguá – Rio de Janeiro – RJ</p>
              <p>
                CEP 22765-240 –{' '}
                <strong>
                  <a href="https://goo.gl/maps/HAujCuvuUzKUsyw59">
                    Veja o mapa
                  </a>
                </strong>
                .
              </p>
              <h3>Por e-mail:</h3>
              <p>
                <strong>
                  <a href="mailto:geodrill@geodrill.com.br?Subject=Digite%20o%20assunto">
                    geodrill@geodrill.com.br
                  </a>
                </strong>
              </p>
              <p>
                <strong>
                  <a href="mailto:geraldo@geodrill.com.br?Subject=Digite%20o%20assunto">
                    geraldo@geodrill.com.br
                  </a>
                </strong>
              </p>
              <p>
                <strong>
                  <a href="mailto:lobato@geodrill.com.br?Subject=Digite%20o%20assunto">
                    lobato@geodrill.com.br
                  </a>
                </strong>
              </p>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  )
}

export default Contatos
