import React from 'react'
import { useParams } from 'react-router-dom'

import PlataformasElevatorias from '../PlataformasElevatorias'
import FundacoesEspeciais from '../FundacoesEspeciais'
import ContencaoDeEncostas from '../ContencaoDeEncostas'
import ObrasCivis from '../ObrasCivis'
import RecuperacaoEstrutural from '../RecuperacaoEstrutural'
import SondagemCampanula from '../SondagemCampanula'
import SondagemFlutuante from '../SondagemFlutuante'
import SondagemPlataforma from '../SondagemPlataforma'
import SondagemVibracore from '../SondagemVibracore'
import SondagemGeotecnica from '../SondagemGeotecnica'

enum Slug {
  PLATAFORMAS_ELEVATORIAS = 'aluguel-de-plataformas-autoelevatorias',
  CONTENCAO_DE_ENCOSTAS = 'contencao-de-encostas',
  FUNDACOES_ESPECIAIS = 'fundacoes-especiais-estaca-raiz',
  OBRAS_CIVIS = 'obras-civis',
  RECUPERACAO_ESTRUTUTAL = 'recuperacao-estrutural',
  SONDAGEM_CAMPANULA = 'sondagens-subaquaticas-com-campanula-de-mergulho',
  SONDAGEM_FLUTUANTE = 'sondagens-subaquaticas-com-flutuantes',
  SONDAGEM_PLATAFORMA = 'sondagens-subaquaticas-com-plataformas',
  SONDAGEM_VIBRACORE = 'sondagens-subaquaticas-com-vibracore',
  SONDAGEM_GEOTECNICA = 'sondagens-geotecnicas',
}

export function Servicos() {
  const { slug } = useParams()

  console.log(slug)

  if (slug === Slug.PLATAFORMAS_ELEVATORIAS) {
    return <PlataformasElevatorias />
  } else if (slug === Slug.CONTENCAO_DE_ENCOSTAS) {
    return <ContencaoDeEncostas />
  } else if (slug === Slug.FUNDACOES_ESPECIAIS) {
    return <FundacoesEspeciais />
  } else if (slug === Slug.OBRAS_CIVIS) {
    return <ObrasCivis />
  } else if (slug === Slug.RECUPERACAO_ESTRUTUTAL) {
    return <RecuperacaoEstrutural />
  } else if (slug === Slug.SONDAGEM_CAMPANULA) {
    return <SondagemCampanula />
  } else if (slug === Slug.SONDAGEM_FLUTUANTE) {
    return <SondagemFlutuante />
  } else if (slug === Slug.SONDAGEM_PLATAFORMA) {
    return <SondagemPlataforma />
  } else if (slug === Slug.SONDAGEM_VIBRACORE) {
    return <SondagemVibracore />
  } else if (slug === Slug.SONDAGEM_GEOTECNICA) {
    return <SondagemGeotecnica />
  }

  return <h1>Not found!</h1>
}
