import { Col } from 'react-bootstrap'

interface ItemProps {
  url?: string
  image: string
}

export function GridItems({ url = '#', image }: ItemProps) {
  return (
    <Col className="theHoverBorder">
      <a href={url}>
        <img src={image} alt="" />
      </a>
    </Col>
  )
}
