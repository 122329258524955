import React from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'

import './styles.css'

function Header() {
  return (
    <Navbar
      fixed="top"
      className="header-menu"
      bg="light"
      variant="light"
      expand="lg"
    >
      <Container>
        <Navbar.Brand href="/">
          <img src="/images/logo.png" alt="" className="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav w-100" />
        <Navbar.Collapse id="basic-navbar-nav w-100">
          <Nav className="nav-links me-auto">
            <Nav.Link href="/">HOME</Nav.Link>
            <Nav.Link href="/sobre">SOBRE</Nav.Link>
            <NavDropdown
              renderMenuOnMount={true}
              autoClose="outside"
              title="SERVIÇOS"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="/servicos/aluguel-de-plataformas-autoelevatorias">
                ALUGUEL DE PLATAFORMAS
              </NavDropdown.Item>
              <NavDropdown.Item href="/servicos/contencao-de-encostas">
                CONTENÇÃO DE ENCOSTAS
              </NavDropdown.Item>
              <NavDropdown.Item href="/servicos/fundacoes-especiais-estaca-raiz">
                FUNDAÇÕES ESPECIAIS
              </NavDropdown.Item>
              <NavDropdown.Item href="/servicos/obras-civis">
                OBRAS CIVIS
              </NavDropdown.Item>
              <NavDropdown.Item href="/servicos/recuperacao-estrutural">
                RECUPERAÇÃO ESTRUTURAL
              </NavDropdown.Item>
              <NavDropdown.Item href="/servicos/sondagens-subaquaticas-com-campanula-de-mergulho">
                SONDAGENS COM CAMPÂNULAS
              </NavDropdown.Item>
              <NavDropdown.Item href="/servicos/sondagens-subaquaticas-com-flutuantes">
                SONDAGENS COM FLUTUANTE
              </NavDropdown.Item>
              <NavDropdown.Item href="/servicos/sondagens-subaquaticas-com-plataformas">
                SONDAGENS COM PLATAFORMA
              </NavDropdown.Item>
              <NavDropdown.Item href="/servicos/sondagens-subaquaticas-com-vibracore">
                SONDAGENS COM VIBRACORE
              </NavDropdown.Item>
              <NavDropdown.Item href="/servicos/sondagens-geotecnicas">
                SONDAGENS GEOTÊCNICAS
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/obras">OBRAS</Nav.Link>
            <Nav.Link href="/clientes">CLIENTES</Nav.Link>
            <Nav.Link href="/contato">CONTATOS</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Header
