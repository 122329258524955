import React from 'react'
import { createBrowserRouter } from 'react-router-dom'

import Root from '../pages/layout/root'
import Home from '../pages/Home'
import Sobre from '../pages/Sobre'
import Obras from '../pages/Obras'
import Clientes from '../pages/Clientes'
import Contato from '../pages/Contato'
import { Servicos } from '../pages/Servicos'

enum Routes {
  HOME = '/',
  SOBRE = '/sobre',
  OBRAS = '/obras',
  CLIENTES = '/clientes',
  CONTATO = '/contato',
  SERVICOS = '/servicos/:slug',
}

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: Routes.HOME,
        element: <Home />,
      },
      {
        path: Routes.SOBRE,
        element: <Sobre />,
      },
      {
        path: Routes.OBRAS,
        element: <Obras />,
      },
      {
        path: Routes.CLIENTES,
        element: <Clientes />,
      },
      {
        path: Routes.CONTATO,
        element: <Contato />,
      },
      {
        path: Routes.SERVICOS,
        element: <Servicos />,
      },
    ],
  },
])
