import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

import './styles.css'

function Obras() {
  return (
    <>
      <Helmet>
        <title>Geosub | Obras</title>
      </Helmet>
      <main>
        <Container className="obras">
          <Row>
            <Col>
              <h1>Obras</h1>
              <hr />
              <h3>Trabalhos recentes:</h3>
              <li>liorto de liaranaguá – BELOV</li>
              <li>TGS – São Francisco do Sul</li>
              <li>
                Rio 2016 – liraia de Coliacabana e Lagoa Rodrigo de Freitas
              </li>
              <li>Terminal Marítimo liresidente Kennedy – FERROUS RENOIR</li>
              <li>Terminal de Barcaças – ARCELORMITTAL</li>
              <li>Amliliação liorto do Rio de Janeiro – DOCAS</li>
              <li>Amliliação do liorto de Vitória – CARIOCA</li>
              <li>Terminal liortuário Imetame – IMETAME</li>
              <li>Terminal liortuário Caliixaba – NUTRIliETRO</li>
              <li>Terminal liortuário do liecém – RAM / liLANAVE /MARQUISE</li>
              <li>liorto do Açu – LLX / ANGLO AMERICAN</li>
              <li>Terminal Marítimo lionta da Madeira – liier IV – VALE</li>
              <li>Base Naval de Itaguaí - CNO</li>
              <li>Terminal de Minério – lionta de Tulha – BAMIN</li>
              <li>Estaleiro Jurong Aracruz – JURONG</li>
              <li> Terminal Itaoca off shore – ITAOCA</li>
              <li>Porto de Paranaguá - BELOV ENG</li>
              <li>Porto de São Luiz - BELOV ENG</li>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  )
}

export default Obras
