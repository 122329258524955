import React from 'react'
import { Helmet } from 'react-helmet'
import { Col, Container, Row } from 'react-bootstrap'

import './styles.css'
import { Grid } from './components/grid'

function Clientes() {
  return (
    <>
      <Helmet>
        <title>Geosub | Clientes</title>
      </Helmet>
      <main>
        <Container className="clientes">
          <Row>
            <Col>
              <h1>Clientes</h1>
              <hr />
              <p>
                A Geosub está acostumada a enfrentar desafios ao lado de grandes
                clientes dos mais diversos cantos do Brasil.
              </p>
              <p>
                Além de atender a empresas e organizações de grande porte como
                IBAMA, Construtora Santa Bárbara, Carvalho Hosken, Arcelor
                Mittal, Prosint – GPC, Embratel, MRS, OI, Votorantin, Bahia
                Mineração, GeoRio, TV Globo, BHP Mineração, VogBR, Sondotecnica,
                ARG, Nokia Siemens e Ericsson, a Geodrill também teve a
                satisfação de trabalhar com:
              </p>
            </Col>
          </Row>
          <Grid />
        </Container>
      </main>
    </>
  )
}

export default Clientes
